body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; overflow-x: hidden !important; }

.scroll-container{
  overflow: auto !important;
  height: 600px !important;
}

#ot-sdk-btn-floating.ot-floating-button {
  bottom: 35px !important
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
